<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Create Exercise</v-toolbar-title
      >

      <v-spacer />
      <v-btn class="ml-5" :loading="exerciseLoading" @click="submit()">
        Create Exercise
      </v-btn>
    </v-app-bar>

    <v-form
      class="exercise-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="4" align="center">
          <ImageUploader :form="form" v-if="!form.thumb_nail_from_cloudinary" />
          <p
            v-if="!form.thumb_nail_from_cloudinary && !(form.photos.length > 0)"
          >
            or
          </p>
          <v-text-field
            v-model.trim="form.thumb_nail_from_cloudinary"
            v-if="!(form.photos.length > 0)"
            class="input__outlined--regular"
            label="Thumbnail from cloudinary"
            outlined
            :error-messages="form.$getError('thumb_nail_from_cloudinary')"
            @input="form.$clearError('thumb_nail_from_cloudinary')"
          />

          <v-divider class="mb-7" />

          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('video') }"
            v-if="!form.video_from_cloudinary"
            @click="uploadThumbnail('video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload Introduction Video
              </span>
            </div>
            <span v-if="form.$getError('video')" class="error--text">{{
              form.$getError('video')[0]
            }}</span>

            <v-overlay :absolute="true" :value="uploadLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>
          <div
            class="upload-container pointer mt-5"
            v-if="checkIVideoThumbNail(form)"
          >
            <v-btn
              fab
              class="radius__button text-none remove-img error"
              x-small
              depressed
              @click="removeVideo()"
            >
              <v-icon small color="white">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
            <video controls :src="form.video[0].url">
              Your browser does not support the video tag.
            </video>
          </div>
          <p v-if="!form.video_from_cloudinary && !(form.video.length > 0)">
            or
          </p>
          <v-text-field
            v-model.trim="form.video_from_cloudinary"
            v-if="!(form.video.length > 0)"
            class="input__outlined--regular"
            label="Video from cloudinary"
            outlined
            :error-messages="form.$getError('video_from_cloudinary')"
            @input="form.$clearError('video_from_cloudinary')"
          />
        </v-col>

        <v-col cols="8">
          <v-text-field
            v-model.trim="form.title"
            class="input__outlined--regular"
            label="Exercise Title"
            outlined
            :error-messages="form.$getError('title')"
            @input="form.$clearError('title')"
          />
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.category_id"
                class="input__outlined--regular"
                label="Category"
                outlined
                :items="categories"
                item-value="id"
                item-text="label"
                :error-messages="form.$getError('category_id')"
                @change="form.$clearError('category_id')"
              />
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="form.is_free"
                :error-messages="form.$getError('is_free')"
                label="Free"
              ></v-switch>
            </v-col>
          </v-row>

          <ContentEditor v-model="form.content" />

          <h2 class="my-5">Alternative Exercises</h2>
          <v-card outlined class="pa-5">
            <div class="d-flex justify-space-between align-center mb-3">
              <p>Add Alternative Exercises</p>
              <v-btn @click="showAltExerciseForm = true">
                Add
              </v-btn>
            </div>

            <v-card
              flat
              outlined
              class="mb-3"
              v-if="alternative_exercises.length > 0"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(altExercise, index) in alternative_exercises"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      altExercise.title
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in menuItems"
                          link
                          :key="i"
                        >
                          <v-list-item-title
                            @click="menuAction(item, altExercise, index)"
                            >{{ item }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="showAltExerciseForm">
      <AlternativeExerciseForm
        :show="showAltExerciseForm"
        :currentAltExercise="currentAltExercise"
        :isEdit="isEdit"
        @onSave="addAltExercise"
        @onUpdate="updateAltExercise"
        @onCancel="cancelAltExercise"
      />
    </div>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      @startUpload="startUpload"
    ></MediaUploader>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUploader from '@/components/ImageUploader'
import ContentEditor from '@/components/ContentEditor'
import AlternativeExerciseForm from '@/components/exercise/AlternativeExerciseForm'
import { mdiDotsVertical, mdiDelete, mdiClose } from '@mdi/js'
import { programMenu } from '@/utils/constants'
import { objectToFormData } from '@/utils/jsonToFormData'
import MediaUploader from '@/components/MediaUploader'

export default {
  name: 'CreateExercise',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ImageUploader,
    ContentEditor,
    AlternativeExerciseForm,
    MediaUploader
  },
  computed: {
    ...mapState('exercise', {
      form: state => state.exerciseForm,
      exerciseLoading: state => state.exerciseLoading
    }),
    ...mapState('exerciseCategory', {
      categories: state => state.categories
    })
  },
  data() {
    return {
      icons: {
        mdiDelete,
        mdiDotsVertical,
        mdiClose
      },
      menuItems: [programMenu().EDIT, programMenu().DELETE],
      alternative_exercises: [],
      currentAltExercise: {
        title: '',
        link: '',
        thumb_nail: null
      },
      showAltExerciseForm: false,
      editIndex: null,
      isEdit: false,
      mediaType: null,
      uploadLoader: false,
      imageState: null
    }
  },
  mounted() {
    this.form.$reset()
    this.getExercieCategories()
  },
  methods: {
    ...mapActions({
      getExercieCategories: 'exerciseCategory/getExercieCategories'
    }),
    ...mapActions({
      createExercise: 'exercise/createExercise'
    }),
    startUpload() {
      this.uploadLoader = true
    },
    addAltExercise(altExercise) {
      this.alternative_exercises.push(altExercise)
      this.resetCurrentExercise()
      this.showAltExerciseForm = false
    },
    cancelAltExercise() {
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentExercise()
      this.showAltExerciseForm = false
    },
    updateAltExercise(altExercise) {
      this.alternative_exercises[this.editIndex] = altExercise
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentExercise()
      this.showAltExerciseForm = false
    },
    menuAction(menu, altExercise, index) {
      switch (menu) {
        case programMenu().EDIT:
          this.isEdit = true
          this.currentAltExercise = altExercise
          this.editIndex = index
          this.showAltExerciseForm = true
          break
        case programMenu().DELETE:
          this.alternative_exercises.splice(index, 1)
          break
      }
    },
    resetCurrentExercise() {
      this.currentAltExercise = {
        title: '',
        link: '',
        thumb_nail: null
      }
    },
    removeVideo() {
      this.form.video = []
    },
    uploadThumbnail(state) {
      if (state === 'video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    onMediaLoad(data) {
      switch (this.imageState) {
        case 'video':
          this.form.video = []
          this.form.video = [
            {
              file: data.file.get('file'),
              url: data.url
            }
          ]
          this.uploadLoader = false
          this.form.$clearError('video')
          break
      }
    },
    checkIVideoThumbNail(image) {
      return image?.video?.[0]?.url || image?.video?.url
    },
    update() {},
    submit() {
      this.alternative_exercises = this.alternative_exercises.map(
        altExercise => {
          if (!(altExercise.thumb_nail instanceof File)) {
            altExercise.thumb_nail = altExercise.thumb_nail[0].file
          }
          return altExercise
        }
      )

      this.form.alternative_exercises = this.alternative_exercises
      const { photos, video, ...exercise } = this.form.$data()
      const form = objectToFormData(exercise)
      if (photos.length > 0) {
        form.append('thumb_nail', photos[0]?.file)
      }
      if (video.length > 0) {
        form.append('video', video[0]?.file)
      }

      this.createExercise(form).then(() => {
        this.alternative_exercises = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: auto;
    width: 100%;
  }
}

.remove-img {
  position: absolute;
  right: -15px;
  top: -15px;
}
</style>
