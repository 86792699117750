<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ capitalizeFirstLetter(action) }} Alternative Exercise</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <div
                class="image-container"
                v-if="checkThumbNail(currentAltExerciseHolder)"
                @click="uploadThumbnail"
              >
                <v-img
                  max-height="140px"
                  :src="
                    currentAltExerciseHolder.thumb_nail[0]
                      ? currentAltExerciseHolder.thumb_nail[0].url
                      : currentAltExerciseHolder.thumb_nail.url
                  "
                  alt="File Upload Placeholder"
                />
              </div>
              <div
                v-else
                class="upload-container pointer"
                @click="uploadThumbnail"
              >
                <v-img
                  :src="require('@/assets/icons/file-upload-placeholder.svg')"
                  alt="File Upload Placeholder"
                  width="80px"
                  height="80px"
                />

                <div class="upload-label mt-3">
                  <span variant="primary">
                    Upload thumbnail
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                required
                label="Title"
                class="mb-7"
                v-model="currentAltExerciseHolder.title"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                outlined
                required
                label="Link"
                v-model="currentAltExerciseHolder.link"
                :rules="[rules.link]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="formActions('cancel')">
          Cancel
        </v-btn>
        <v-btn
          :disabled="!checkDisabled"
          :loading="loading"
          @click="isEdit ? formActions('update') : formActions('save')"
        >
          {{ `${isEdit ? 'Update' : 'Add'}` }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
    ></MediaUploader>
  </v-dialog>
</template>

<script>
import MediaUploader from '@/components/MediaUploader'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'Add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentAltExercise: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentAltExerciseHolder: { ...this.currentAltExercise },
      rules: {
        link: value => {
          const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
          return pattern.test(value) || 'Invalid Link'
        }
      }
    }
  },
  computed: {
    checkDisabled() {
      const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
      return (
        this.currentAltExerciseHolder.title &&
        this.currentAltExerciseHolder.link &&
        pattern.test(this.currentAltExerciseHolder.link) &&
        this.currentAltExerciseHolder.thumb_nail
      )
    }
  },
  mounted() {
    this.currentAltExerciseHolder = { ...this.currentAltExercise }
  },
  components: {
    MediaUploader
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    formActions(action) {
      if (action === 'update') {
        this.$emit('onUpdate', this.currentAltExerciseHolder)
      } else if (action === 'save') {
        this.$emit('onSave', this.currentAltExerciseHolder)
      } else {
        this.$emit('onCancel')
      }
    },
    uploadThumbnail() {
      this.$refs.triggerUpload.openFile()
    },
    checkThumbNail(currentAltExerciseHolder) {
      return (
        currentAltExerciseHolder?.thumb_nail?.[0]?.url ||
        currentAltExerciseHolder?.thumb_nail?.url
      )
    },
    onMediaLoad(data) {
      this.currentAltExerciseHolder.thumb_nail = [
        {
          file: data.file.get('file'),
          url: data.url
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__subtitle {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-container {
  max-height: 140;
  width: auto;
}

.upload-container {
  width: 100%;
  height: auto;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #a31f314d;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }
}
</style>
